/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-use-before-define */

import React, { useState, useContext } from 'react';
import { navigate } from 'gatsby';
// import _forEach from 'lodash/forEach'
import { Header, Form, Input, Button, Segment } from 'semantic-ui-react';
import SEO from '../components/SEO';
import AuthContext from '../components/Context/AuthContext';
import AccountService from '../services/account';
import Layout from '../components/Layout';
import useForm from '../components/Hooks/useForm';
import handleErrors from '../components/HandleErrors';

const Register = ({ location }) => {
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState([]);
  const { updateToken } = useContext(AuthContext);

  const formRegister = () => {
    setLoading(true);

    AccountService.register({
      name: values.name,
      email: values.email,
      document: values.document,
      phone: values.phone,
      password: values.password,
      password_confirmation: values.password_confirmation,
    })
      .then(data => {
        const { id, token } = data;
        localStorage.setItem('customerToken', token);
        localStorage.setItem('user_id', id);
        updateToken();
        navigate('/myaccount/');
        setLoading(true);
      })
      .catch(e => {
        setLoading(false);
        setApiError(e.response.data.errors || e);
      });
  };

  const { values, handleChange, handleSubmit, errors } = useForm(
    formRegister,
    validate
  );

  return (
    <Layout location={location}>
      <SEO title="Register" />
      <Header as="h1">Create an account</Header>
      <Form onSubmit={handleSubmit} loading={loading} error={!!errors}>
        {apiError.length !== 0 ? handleErrors(apiError) : null}

        <Segment>
          <Form.Field>
            <label htmlFor="name">Nome</label>
            <Input
              id="name"
              fluid
              name="name"
              autoFocus
              onChange={handleChange}
              value={values.name || ''}
            />
          </Form.Field>
          {errors.name && <p style={{ color: 'red' }}>{errors.name}</p>}

          <Form.Field>
            <label htmlFor="email">E-mail</label>
            <Input
              id="email"
              fluid
              name="email"
              type="email"
              onChange={handleChange}
              value={values.email || ''}
            />
          </Form.Field>
          {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}

          <Form.Field>
            <label htmlFor="phone">Telefone</label>
            <Input
              id="phone"
              fluid
              name="phone"
              onChange={handleChange}
              value={values.phone || ''}
            />
          </Form.Field>
          {errors.phone && <p style={{ color: 'red' }}>{errors.phone}</p>}

          <Form.Field>
            <label htmlFor="document">CPF/CNPJ</label>
            <Input
              id="document"
              fluid
              name="document"
              onChange={handleChange}
              value={values.document || ''}
            />
          </Form.Field>
          {errors.document && <p style={{ color: 'red' }}>{errors.document}</p>}

          <Form.Field>
            <label htmlFor="password">Senha</label>
            <Input
              id="password"
              fluid
              name="password"
              type="password"
              onChange={handleChange}
              value={values.password || ''}
            />
          </Form.Field>
          {errors.password && <p style={{ color: 'red' }}>{errors.password}</p>}

          <Form.Field>
            <label htmlFor="password">Confirmar Senha</label>
            <Input
              id="password_confirmation"
              fluid
              name="password_confirmation"
              type="password"
              onChange={handleChange}
              value={values.password_confirmation || ''}
            />
          </Form.Field>
          {errors.password_confirmation && (
            <p style={{ color: 'red' }}>{errors.password_confirmation}</p>
          )}
          <Button type="submit" color="orange">
            Register
          </Button>
        </Segment>
      </Form>
    </Layout>
  );
};

export default Register;

const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'A name is required';
  }
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }
  if (!values.phone) {
    errors.phone = 'A phone is required';
  }
  if (!values.document) {
    errors.document = 'A document is required';
  }
  if (!values.password) {
    errors.password = 'Password is required';
  }
  if (!values.password_confirmation) {
    errors.password_confirmation = 'Password confirmation is required';
  }
  if (values.password_confirmation && values.password) {
    if (values.password_confirmation !== values.password) {
      errors.password_confirmation =
        'Password confirmation not equal password.';
    }
  }
  return errors;
};
